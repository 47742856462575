import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CampaignAnalysis = lazy(() => import("../components/CampaignAnalysis"));

const CampaignAnalysisRoute: RouteObject = {
  path: "/campaign-analysis",
  element: <CampaignAnalysis />,
};

export default CampaignAnalysisRoute;
